<template>
    <div class="action-premium-survey-end">
        <template>
            <div class="message">
                <div class="message-top" v-html="$translate('ACTION_PREMIUM_SURVEY_END_TITLE')" />
                <div class="message-mid" v-html="$translate('ACTION_PREMIUM_SURVEY_END_BODY')" />
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'ActionPremiumSurveyEnd',
    props: ['message'],
    computed: {
        chat() {
            return this.$store.getters.chat
        },
        content() {
            return this.$mustParse(this.message.content) || {}
        },
        user() {
            return this.content.user || {}
        },
        profile() {
            return this.chat.user.profile
        },
        agentId() {
            return this.chat.agent_id
        },
    },
    methods: {},
}
</script>

<style lang="scss" scoped>
.action-premium-survey-end {
    .profile-container,
    .question,
    .button-container {
        padding: 0 12px;
    }

    .hr {
        width: 100%;
        height: 1px;
        background: $grey-02;
        margin-top: 8px;
        margin-bottom: 12px;
    }

    .profile-container {
        display: flex;

        .photo {
            width: 44px;
            height: 44px;
            border-radius: 40%;
            border: solid 1px $grey-02;
            margin-right: 12px;
        }

        .profile {
            width: 100%;
            align-self: center;

            .name {
                font-size: 14px;
                height: 20px;
                color: black;

                @include f-medium;
            }

            .sub {
                display: flex;
                font-size: 12px;
                width: 100%;

                .job,
                .location {
                    height: 18px;
                    color: $grey-07;
                }
                .bar {
                    color: $grey-04;
                    margin-left: 4px;
                    margin-right: 4px;
                }
            }
        }

        .profile-btn {
            width: 36px;
            min-width: 36px;
            height: 36px;
            border-radius: 8px;
            background-color: $grey-02;
            display: flex;
            object-fit: contain;
            justify-content: center;
            align-items: center;
        }
    }

    .message {
        margin-left: 12px;

        .message-top {
            font-size: 16px;
            font-family: NotoSans-Medium;
            line-height: 1.33;
            text-align: left;
            color: black;
            margin-bottom: 12px;
        }
        .message-mid {
            font-size: 12px;
            line-height: 1.33;
            text-align: left;
            color: $grey-09;
            margin-bottom: 12px;
        }
        .message-bottom {
            font-size: 12px;
            line-height: 1.33;
            text-align: left;
            color: #262289;
            margin-bottom: 12px;
        }
    }
}
</style>
